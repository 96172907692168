import { useTranslation } from "react-i18next";

const ConfirmationDialog = ({ title, handleConfirm, handleCancel, showDialog, primary}) => {

    const { t } = useTranslation();
    
    return (
        <>
            <div className={`bg-[rgba(0,0,0,0.7)] fixed top-0 flex justify-end flex-col h-full w-full z-20 ${showDialog ? 'block' : 'hidden'}`}>
            </div>
            <div className={`absolute left-[0px] z-30 transition ease-in-out duration-500 w-full ${showDialog ? 'show-dialog top-10' : 'hide-dialog top-0'}`}>
                <div className="w-[80%] text-center mx-[auto] max-w-[80%] bg-white rounded-md py-2 px-3 relative">
                    <p className="text-[15px] mb-2 font-semibold max-w-[90%] mx-[auto] mt-4">{title}</p>
                    <div className="flex items-center justify-center mt-4 mb-3">
                        <button className={`mx-2 border-0 bg-[transparent] text-black text-[12px] rounded-[5px] px-4 py-2 font-semibold`} onClick={handleCancel}>{t('No')}</button>
                        <button className={`mx-2 border-0 text-white text-[12px] px-4 py-2 rounded-[5px] bg-[#ff0800] font-semibold`} onClick={handleConfirm}>{t('Yes')}</button>
                    </div>
                    <img src="/assets/images/Cross.svg" width={18} height={18} alt="Close" className="absolute top-2 right-2 cursor-pointer" onClick={handleCancel} />
                </div>
            </div>
        </>
    )
}

export default ConfirmationDialog;